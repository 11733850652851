import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilAccountLogout,
  cilApplications,
  cilDescription,
  cilFingerprint,
  cilHistory,
  cilImage,
  cilKeyboard,
  cilMagnifyingGlass,
  cilSettings,
  cilSpeedometer,
} from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const fullPath = window.location.pathname + window.location.hash
const isHPPath = fullPath.includes('/hp')
localStorage.setItem('env', isHPPath ? 'hp' : 'default')
const path = isHPPath ? '/hp' : ''
const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: path + '/startup',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'CREATE',
    },
  },
  {
    component: CNavTitle,
    name: 'User Tools',
  },
  {
    component: CNavItem,
    name: 'Image to Code',
    to: path + '/imagecanvas',
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Prompt to Code',
    to: path + '/prompt2code',
    icon: <CIcon icon={cilKeyboard} customClassName="nav-icon" />,
    badge: {
      color: 'error',
      text: 'LOCKED',
    },
  },
  {
    component: CNavItem,
    name: 'Prompt to App',
    to: path + '/500',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    badge: {
      color: 'error',
      text: 'LOCKED',
    },
  },
  {
    component: CNavTitle,
    name: 'Extras',
  },
  // {
  //   component: CNavItem,
  //   name: 'HP',
  //   to: '/imagecanvas?settings=HP',
  //   icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Preview',
  //   to: '/preview',
  //   icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Logout',
    to: '/login?logout=true',
    icon: <CIcon icon={cilAccountLogout} customClassName="nav-icon" />,
  },
]

export default _nav
