import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import Test from './layout/test'
// import 'bootstrap/dist/css/bootstrap.min.css'

// MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig } from './msalConfig'
import { MsalProvider } from '@azure/msal-react'

const msalInstance = new PublicClientApplication(msalConfig.msalConfig)
await msalInstance.initialize()
await msalInstance.handleRedirectPromise() // This will no longer throw this error since initialize completed before this was invoked

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

if (msalInstance.getActiveAccount()) {
  msalInstance
    .ssoSilent({
      ...msalConfig.apiConfig.scopes,
      account: msalInstance.getActiveAccount(),
    })
    .catch((error) => console.log(error))
} else {
  msalInstance
    .loginRedirect({
      ...msalConfig.apiConfig.scopes,
      prompt: 'create',
    })
    .catch((error) => console.log(error))
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
