export const msalConfig = {
  msalConfig: {
    auth: {
      clientId: 'aa617587-f6ba-4afa-a1fb-bdfd6026a4ef',
      authority: 'https://login.microsoftonline.com/d7758e8f-1df3-489f-86b5-a2254f55f9cc',
      // redirectUri: 'http://localhost:4200/',
      redirectUri: 'https://angelo.avateam.io/',
      postLogoutRedirectUri: '/',
    },
  },
  apiConfig: {
    scopes: ['https://graph.microsoft.com/User.Read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
}
