import React, { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import routes from '../routes'

const MContent = () => {
  const [env, setEnv] = useState(false)

  useEffect(() => {
    // const fullPath = window.location.pathname + window.location.hash
    // const isHPPath = fullPath.includes('/hp')
    // localStorage.setItem('env', isHPPath ? 'hp' : 'default')
    // setEnv(isHPPath ? '/hp/' : '/wick/')
  }, [])
  return (
    <CContainer fluid>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  // path={env + route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                  replace
                />
              )
            )
          })}
          <Route path="/hp" element={<Navigate to="startup" replace />} />
          <Route path="/" element={<Navigate to="startup" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default MContent
