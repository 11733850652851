import React from 'react'
import { MContent } from '../components/index'
import MSideBar from 'src/components/MSideBar'

const MLayout = () => {
  return (
    <div>
      <MSideBar />
      <div
        className="wrapper d-flex flex-column min-vh-100"
        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
      >
        {/* <MHeader /> */}
        {/* <div className="body flex-grow-1"> */}
        <MContent />
        {/* </div> */}
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default MLayout
