import React from 'react'

const ImageCanvas = React.lazy(() => import('./views/ImageToCode/ImageToCode'))
const Startup = React.lazy(() => import('./views/startup/startup'))

const routes = [
  { path: '/imagecanvas', exact: true, name: 'ImageCanvas', element: ImageCanvas },
  { path: '/startup', exact: true, name: 'Startup', element: Startup },
  { path: '/hp/startup', exact: true, name: 'hp', element: Startup },
  { path: '/hp/imagecanvas', exact: true, name: 'HPImageCanvas', element: ImageCanvas },
]

export default routes
